var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("区域设置")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
      "label": "关键字搜索（按回车）",
      "prepend-inner-icon": "search",
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchKey();
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-card-subtitle', [_vm._v(" 区域管理可以维护区域名称和区域代码，区域将用于录入合同时选择所属区域 ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.areaList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.areaCount,
      "loading": _vm.areaLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [10, 20, 50, 100]
      },
      "item-class": _vm.itemRowBackground,
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_area', '', '新增区域');
            }
          }
        }, [_c('v-icon', [_vm._v("add")]), _vm._v(" 新建区域 ")], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.openFilter.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_area', item, '编辑区域');
            }
          }
        }, [_vm._v("edit")]), !item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_area', item, true);
            }
          }
        }, [_vm._v("check_circle_outline")]) : _vm._e(), item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_area', item, false);
            }
          }
        }, [_vm._v("block")]) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("重新获取数据")])];
      },
      proxy: true
    }])
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("是否启用")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "mandatory": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.isEnable,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isEnable", $$v);
      },
      expression: "query.isEnable"
    }
  }, _vm._l(_vm.dictEnable, function (enable) {
    return _c('v-chip', {
      key: enable.code,
      attrs: {
        "value": enable.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(enable.name))]);
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "450px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.dlgTitle))]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "区域名称",
      "hint": "请输入区域名称",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "区域代码",
      "hint": "请输入区域代码",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.code,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "code", $$v);
      },
      expression: "editedItem.code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": "",
      "outlined": "",
      "label": "能源负责人",
      "hint": "请选择该区域能源负责人"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.directorEnergy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "directorEnergy", $$v);
      },
      expression: "editedItem.directorEnergy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "能源核定人",
      "hint": "请选择该区域能源结算核定人",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.accountEnergy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "accountEnergy", $$v);
      },
      expression: "editedItem.accountEnergy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "售电负责人",
      "hint": "请选择该区域售电负责人",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.directorSale,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "directorSale", $$v);
      },
      expression: "editedItem.directorSale"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "售电核定人",
      "hint": "请选择该区域售电结算核定人",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.accountSale,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "accountSale", $$v);
      },
      expression: "editedItem.accountSale"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "调峰负责人",
      "hint": "请选择该区域调峰负责人",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.directorPeak,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "directorPeak", $$v);
      },
      expression: "editedItem.directorPeak"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "调峰核定人",
      "hint": "请选择该区域调峰结算核定人",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.accountPeak,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "accountPeak", $$v);
      },
      expression: "editedItem.accountPeak"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "智科负责人",
      "hint": "请选择该区域智科负责人",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.directorZhike,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "directorZhike", $$v);
      },
      expression: "editedItem.directorZhike"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "智科核定人",
      "hint": "请选择该区域智科结算核定人",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.accountZhike,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "accountZhike", $$v);
      },
      expression: "editedItem.accountZhike"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }