<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>区域设置</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
          @keydown.enter="searchKey()"
          label="关键字搜索（按回车）"
          prepend-inner-icon="search"
          solo
          flat
          dense
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
      <v-card-subtitle>
        区域管理可以维护区域名称和区域代码，区域将用于录入合同时选择所属区域
      </v-card-subtitle>
      <v-data-table
        :headers="headerTable"
        :items="areaList"
        :options.sync="optionsTable"
        :server-items-length="areaCount"
        :loading="areaLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [10, 20, 50, 100]}"
        :item-class="itemRowBackground"
        item-key="_id"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="doAction('create_area', '', '新增区域')">
              <v-icon>add</v-icon>
              新建区域
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="doAction('edit_area', item, '编辑区域')">edit</v-icon>
          <v-icon small @click="doAction('active_area', item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
          <v-icon small @click="doAction('active_area', item, false)" v-if="item.isEnable">block</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn text @click="getDataList()">重新获取数据</v-btn>
        </template>
      </v-data-table>
      <v-navigation-drawer v-model="filterDrawer" app right>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              过滤条件
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
          <h2 class="subtitle-2 mt-2">是否启用</h2>
          <v-chip-group v-model="query.isEnable" column mandatory @change="setFilter">
            <v-chip v-for="enable in dictEnable" :key="enable.code" :value="enable.code" filter label outlined>{{enable.name}}</v-chip>
          </v-chip-group>
        </v-card-text>
      </v-navigation-drawer>
      <v-dialog v-model="dlgEdit" persistent scrollable max-width="450px">
        <v-form v-model="valid" @submit.prevent="submit">
          <v-card>
            <v-card-title>{{dlgTitle}}</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="[rules.required]"
                      type="text"
                      label="区域名称"
                      hint="请输入区域名称"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.code"
                      :rules="[rules.required]"
                      type="text"
                      label="区域代码"
                      hint="请输入区域代码"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.directorEnergy"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      multiple
                      small-chips
                      deletable-chips
                      outlined
                      label="能源负责人"
                      hint="请选择该区域能源负责人"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.phone}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.accountEnergy"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      label="能源核定人"
                      hint="请选择该区域能源结算核定人"
                      clearable
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.username}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.directorSale"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      label="售电负责人"
                      hint="请选择该区域售电负责人"
                      multiple
                      small-chips
                      deletable-chips
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.phone}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.accountSale"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      label="售电核定人"
                      hint="请选择该区域售电结算核定人"
                      clearable
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.username}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.directorPeak"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      label="调峰负责人"
                      hint="请选择该区域调峰负责人"
                      multiple
                      small-chips
                      deletable-chips
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.phone}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.accountPeak"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      label="调峰核定人"
                      hint="请选择该区域调峰结算核定人"
                      clearable
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.username}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.directorZhike"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      label="智科负责人"
                      hint="请选择该区域智科负责人"
                      multiple
                      small-chips
                      deletable-chips
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.phone}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.accountZhike"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :filter="filterEmployee"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      label="智科核定人"
                      hint="请选择该区域智科结算核定人"
                      clearable
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.username}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close">取消</v-btn>
              <v-btn color="primary" text @click="submit" :disabled="!valid">保存</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>import { mapGetters } from "vuex";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_AREA_LIST,
  PUBLISH_AREA,
  ACTIV_AREA,
  EDIT_AREA,
  FETCH_EMPLOYEE_LIST,
} from "@/store/actions.type";
import Util from "@/common/util";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedIndex: -1,
      editedItem: {
        _id: '',
        name: '',
        code: '',
        accountEnergy: '',
        accountPeak: '',
        accountSale: '',
        accountZhike: '',
        directorEnergy: '',
        directorPeak: '',
        directorSale: '',
        directorZhike: '',
      },
      defaultItem: {
        _id: '',
        name: '',
        code: '',
        accountEnergy: '',
        accountPeak: '',
        accountSale: '',
        accountZhike: '',
        directorEnergy: '',
        directorPeak: '',
        directorSale: '',
        directorZhike: '',
      },
      filterDrawer: false,
      query: {
        key: '',
        isEnable: true,
        code: ''
      },
      defaultQuery: {
        key: '',
        isEnable: true,
        code: ''
      },
      headerTable: [
        {text: '区域名称', width: '105px', value: 'name'},
        {text: '区域代码', width: '225px', value: 'code'},
        {text: '操作', width: '85px', value: 'actions', align: 'end', sortable: false}
      ],
      optionsTable: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_EMPLOYEE_LIST, 'limit=-1')
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "areaLoading",
      "areaList",
      "areaCount",
      "employeeLoading",
      "employeeList"]),
    dictEnable () {
      return Util.categories('dictEnable')
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({path: '/distributor/system'});
    },
    searchKey() {
      if (this.query.key !== '') {
        this.getDataList({key: this.query.key})
      } else {
        this.getDataList()
      }
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList()
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList()
    },
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    getDataList() {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.isEnable !== null && this.query.isEnable !== undefined) urlQuery += '&isEnable='+ this.query.isEnable
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_AREA_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item, customitem) {
      switch (action) {
        case 'create_area': case 'edit_area': {
          this.editedIndex = this.areaList.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'active_area': {
          if (confirm(`确定要 ${customitem ? '启用' : '禁用'} ${item.name}？`)) {
            store.dispatch(ACTIV_AREA, {_id: item._id, reply: customitem})
              .then(() => {
                store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    submit() {
      let strInsert = {}
      strInsert.name = this.editedItem.name
      strInsert.code = this.editedItem.code
      strInsert.accountEnergy = this.editedItem.accountEnergy
      strInsert.accountPeak = this.editedItem.accountPeak
      strInsert.accountSale = this.editedItem.accountSale
      strInsert.accountZhike = this.editedItem.accountZhike
      strInsert.directorEnergy = this.editedItem.directorEnergy;
      strInsert.directorPeak = this.editedItem.directorPeak;
      strInsert.directorSale = this.editedItem.directorSale;
      strInsert.directorZhike = this.editedItem.directorZhike;

      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_AREA, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_AREA, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    itemRowBackground: function (item) {
      if (!item.isEnable) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
    },
    filterEmployee(item, search) {
      return (
        item.username.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        || (item.personal && item.personal.name && item.personal.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
      )
    },
  }
}
</script>
<style scoped>
.gray-img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
